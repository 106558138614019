const DiggerEnterprise = () => {

  return (
    <div class="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <h4 className="mt-5 mb-3">Digger Enterprise</h4>
          <a href="https://calendly.com/diggerdev/digger-open-source-terraform-cloud-alternativ-clone" role="button" class="btn btn-primary mt-4">Book a demo</a>
          <span class="text-muted ms-3 position-relative" style={{top: "14px"}}>Dashboard, Private VCS, SSO, Drift, RBAC and much more</span>
        </div>
      </div>
    </div>
  )
}

export default DiggerEnterprise;