import {React, useEffect } from 'react';
import { Routes, Route } from "react-router-dom"
import styled from 'styled-components';
import { useAuth, useLoginWithRedirect, ContextHolder, useAuthActions, useTenantsState } from '@frontegg/react';
import { AdminPortal } from '@frontegg/react';

import Quickstart from './Quickstart';
import DiggerEnterprise from './DiggerEnterprise';

const StyledSelect = styled.select`
`;

const Span = styled.span`
`;

const LogoutButton = styled(Span).attrs(props => ({
  onClick: props.logout,
  className: "logout-button"
}))``;

const AdminPortalButton = styled(Span).attrs(props => ({
  onClick: props.handleClick
}))``;

const DashboardLinkButton = styled(Span).attrs(props => ({
  onClick: props.handleClick
}))``;

const GithubLinkButton = styled(Span).attrs(props => ({
  onClick: props.handleClick
}))``;

const DocsLinkButton = styled(Span).attrs(props => ({
  onClick: props.handleClick
}))``;

const TenantSelector = styled(StyledSelect).attrs(props => ({
  onChange: props.handleTenantChange
}))``;

const handleAdminPortalClick = () => {
  AdminPortal.show();
};

const navigateToDashboard = () => {
  window.location.href = `/enterprise`;
};

const handleGithubClick = () => {
  window.location = `https://github.com/diggerhq/digger`;
};

const handleDocsClick = () => {
  window.location = `https://docs.digger.dev`;
};

function getTopLevelDomain() {
  var hostname = window.location.hostname;
  var parts = hostname.split('.');
  if (parts.length > 1) {
      return parts.slice(-2).join('.');
  }
  return hostname;
}

function App() {
  const MainPage = () => {
    return (
        <div id="wrapper">
          <nav className="navbar align-items-start sidebar sidebar-dark accordion bg-gradient-primary navbar-dark">
            <div className="container container-fluid d-flex flex-column h-100">
              <a className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" href="/#">
                <div className="sidebar-brand-icon">
                  <svg width="40" height="40" viewBox="0 0 31 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4307 4.77003L9.60067 7.68003C9.41622 7.77278 9.2619 7.91601 9.15568 8.09305C9.04946 8.27008 8.9957 8.47364 9.00067 8.68003V16.25C8.99407 16.438 9.03665 16.6243 9.12419 16.7907C9.21173 16.9572 9.3412 17.0978 9.4998 17.1988C9.65839 17.2999 9.84061 17.3577 10.0284 17.3667C10.2163 17.3757 10.4032 17.3355 10.5707 17.25L16.4007 14.33C16.5859 14.2383 16.7409 14.0952 16.8472 13.918C16.9536 13.7407 17.0068 13.5367 17.0007 13.33V5.74003C17.002 5.55464 16.9555 5.37203 16.8658 5.20978C16.7761 5.04754 16.6461 4.91113 16.4883 4.81368C16.3306 4.71623 16.1505 4.66103 15.9652 4.65338C15.78 4.64574 15.5959 4.68591 15.4307 4.77003V4.77003Z" fill="#FF12E7"></path><path d="M1.57048 4.77003L7.40048 7.68003C7.58493 7.77278 7.73924 7.91601 7.84547 8.09305C7.95169 8.27008 8.00544 8.47364 8.00048 8.68003V16.25C8.00708 16.438 7.9645 16.6243 7.87696 16.7907C7.78942 16.9572 7.65994 17.0978 7.50135 17.1988C7.34275 17.2999 7.16053 17.3577 6.97272 17.3667C6.7849 17.3757 6.59799 17.3355 6.43048 17.25L0.600483 14.28C0.415257 14.1883 0.260264 14.0452 0.153908 13.868C0.047552 13.6907 -0.0056975 13.4867 0.000482946 13.28V5.74003C-0.00081636 5.55464 0.0456338 5.37203 0.135362 5.20978C0.225089 5.04754 0.355073 4.91113 0.5128 4.81368C0.670526 4.71623 0.850683 4.66103 1.03593 4.65338C1.22117 4.64574 1.40526 4.68591 1.57048 4.77003V4.77003Z" fill="#8101E4"></path><path d="M10.48 6.26L9.09001 7C8.90596 7.08862 8.7043 7.13464 8.50001 7.13464C8.29573 7.13464 8.09407 7.08862 7.91001 7L6.52001 6.26C6.36318 6.18297 6.23122 6.06332 6.13925 5.91476C6.04729 5.76619 5.99903 5.59472 6.00001 5.42V2.5C6.00001 1.83696 6.26341 1.20107 6.73225 0.732233C7.20109 0.263392 7.83697 0 8.50001 0V0C9.16306 0 9.79894 0.263392 10.2678 0.732233C10.7366 1.20107 11 1.83696 11 2.5V5.42C11.001 5.59472 10.9527 5.76619 10.8608 5.91476C10.7688 6.06332 10.6368 6.18297 10.48 6.26V6.26Z" fill="#FF8A20"></path></svg>
                </div>
              </a>
              <hr className="sidebar-divider my-0" />
              <ul className="navbar-nav text-light" id="accordionSidebar">
                <li className="nav-item">
                    <LogoutButton logout={logout}>Logout</LogoutButton>
                </li>
                <li className="nav-item">
                    <AdminPortalButton handleClick={handleAdminPortalClick}>Admin</AdminPortalButton>
                </li>
                <li className="nav-item">
                    <DashboardLinkButton handleClick={navigateToDashboard}>Dashboard</DashboardLinkButton>
                    <span class="pro-tag badge bg-success rounded-pill ms-2">EE</span>
                </li>
                <li className="nav-item">
                    <GithubLinkButton handleClick={handleGithubClick}>GitHub</GithubLinkButton>
                </li>
                <li className="nav-item">
                    <DocsLinkButton handleClick={handleDocsClick}>Docs</DocsLinkButton>
                </li>
              </ul>
              <div class="row mt-auto">
                <div class="col">
                <TenantSelector className="form-select" handleTenantChange={handleTenantChange}>
                  {tenants.map((option, index) => (
                      <option key={index} value={option.tenantId} selected={option.tenantId === user.tenantId}>
                        {option.name}
                      </option>
                  ))}
                </TenantSelector>
                </div>
              </div>
            </div>
          </nav>
          <div className="d-flex flex-column" id="content-wrapper">
            <div id="content">

            <Routes>
              <Route path="/" element={<Quickstart />} />
              <Route path="enterprise" element={<DiggerEnterprise />} />
            </Routes>

            </div>
          </div>
          <footer class="bg-white sticky-footer">

        </footer>
        </div>
  );
  };

  const { switchTenant } = useAuthActions();
  const { tenants} = useTenantsState();
  const { user, isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  const logout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  const handleTenantChange = (event) => {
    const newTenantId = event.target.value;
    console.log('Switching to tenant', newTenantId);
    switchTenant({ tenantId: newTenantId });
  };

  if (!isAuthenticated) {
    loginWithRedirect()
  }

  useEffect(() => {
    if (isAuthenticated && user) {
      // Set a cookie for the user when authenticated
      document.cookie = `token=${user?.accessToken};domain=${getTopLevelDomain()};path=/`;
      const baseUrl = ContextHolder.getContext().diggerBaseUrl;
      fetch(`${baseUrl}/tenants/associateTenantIdToDiggerOrg`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`
        }
      });
    }
  }, [user, isAuthenticated]);

  return (
      <div>
      { isAuthenticated ? (
          <MainPage />
      ) : (
          <div>Not authenticated</div>
      )}
      </div>
  );
}

export default App;