import { useState } from 'react';
import { ContextHolder, useAuth } from '@frontegg/react';

const Quickstart = () => {

  const [token, setToken] = useState("");
  const { user } = useAuth();

  const handleTokenCopyClick = (event) => {
    navigator.clipboard.writeText(token);
  }

  function generateToken() {
      const baseUrl = ContextHolder.getContext().baseUrl;
      fetch(`${baseUrl}/frontegg/identity/resources/tenants/access-tokens/v1`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`
        },
        body: JSON.stringify({
          "description": "default",
          "expiresInMinutes": null,
          "roleIds": [ContextHolder.getContext().roleId]
        })
      }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setToken(data.secret);
          });
        } else {
          throw response;
        }
      }).catch(console.error);
  }

  const TokenButton = () => {
    if (!token) {
      return (
        <button class="btn btn-outline-secondary" type="button" onClick={generateToken} id="button-generate-token">Generate</button>
      )
    } else {
      return (
        <button class="btn btn-outline-secondary" type="button" onClick={handleTokenCopyClick} id="button-copy-token">Copy</button>
      )
    }
  }

  return (
    <div class="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <h4 className="mt-5 mb-3">1. Get your Digger token</h4>
          <div class="input-group mb-3">
            <input type="text" value={token} class="form-control" placeholder="Your Digger token" aria-label="Token" aria-describedby="button-addon2" />
            <TokenButton />
          </div>
          <h4 className="mt-4 mb-3">2. Install Digger GitHub app</h4>
          <AppInstallBlock />
          <h4 className="mt-4 mb-3">3. Set up your repo</h4>
          <p>
            <a href="https://docs.digger.dev/getting-started/github-actions-+-aws">Quickstart for AWS</a>
          </p>
          <p><a href="https://docs.digger.dev/getting-started/github-actions-and-gcp">Quickstart for GCP</a>
          </p>
        </div>
      </div>
    </div>
  )
}

function isAppSuccess() {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  return Boolean(params.get("appSuccess"));
}

const AppInstallBlock = () => {
  const installGithubAppUrl = `https://github.com/apps/${ContextHolder.getContext().githubAppId}/installations/select_target`;
  if (isAppSuccess()) {
    return (
      <div>
        <span>App installed successully!</span>
        <a href={installGithubAppUrl} role="button" class="btn btn-outline-secondary ms-3">Configure App</a>
      </div>
    )
  } else {
    return <a href={installGithubAppUrl} role="button" class="btn btn-primary">Install App</a>
  }
}

export default Quickstart;